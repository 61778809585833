import React, { useState } from 'react';
import { db, collection, addDoc } from '../Backend/Firebase';
import './styles/ApplyForJob.css';

const ApplyForJob = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    position: '',
    location: '',
    phone: '',
    resume: null,
    message: ''
  });

  const [message, setMessage] = useState({ text: '', type: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });
  };

  const validateForm = () => {
    const { name, email, phone, location } = formData;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!name || !email || !phone || !location) {
      setMessage({ text: 'All fields are required.', type: 'error' });
      return false;
    }

    if (!emailPattern.test(email)) {
      setMessage({ text: 'Invalid email format.', type: 'error' });
      return false;
    }

    if (!phonePattern.test(phone)) {
      setMessage({ text: 'Phone number should be 10 digits.', type: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ text: '', type: '' }); // Clear previous messages

    if (!validateForm()) return;

    try {
      const docRef = await addDoc(collection(db, 'applications'), formData);
      console.log('Document written with ID: ', docRef.id);
      setMessage({ text: 'Your application has been submitted successfully.', type: 'success' });
      setSubmitted(true);
    } catch (e) {
      console.error('Error adding document: ', e);
      setMessage({ text: 'There was an error submitting your application. Please try again.', type: 'error' });
    }
  };

  return (
    <div className="apply-for-job-container">
      <div className="apply-for-job-bg-image"></div>
      <div className="apply-for-job-logo-container">
        <img src="/media/scs-logo.png" alt="SharpClean Logo" className="sharp-clean-logo" />
      </div>
      <h2>Apply for Job</h2>
      {!submitted ? (
        <form className="apply-for-job-form" onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Position:
            <input type="text" name="position" value={formData.position} onChange={handleChange} required />
          </label>
          <label>
            Location:
            <input type="text" name="location" value={formData.location} onChange={handleChange} required />
          </label>
          <label>
            Phone:
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
          </label>
          <label>
            Resume:
            <input type="file" name="resume" onChange={handleChange} required />
          </label>
          <label>
            Message:
            <textarea name="message" value={formData.message} onChange={handleChange} required />
          </label>
          <button type="submit">Submit</button>
        </form>
      ) : (
        <p className={`form-message ${message.type}`}>{message.text}</p>
      )}
    </div>
  );
};

export default ApplyForJob;
