import React from 'react';
import './styles/Home.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

function Home() {

  return (
    <div className="home-main">
      <header className="header">
        <Link to="/" className="logo-link">
          <img src="/media/scs-logo.png" alt="Sharpclean logo" className="logo" />
        </Link>
        <div className="contact-info">
          <span className="contact-item">
            <i className="fas fa-phone"></i> (980) 422-5603
          </span>
          <span className="contact-item">
            <i className="fas fa-envelope"></i>
            <a href="mailto:contact@sharpcleaningsolutions.com">contact@csharpent.com</a>
          </span>
          <Link to="/about-us" className="about-link">About Us</Link>
          <Link to="/book-now" className="book-button">Book Now</Link>
        </div>
      </header>

      <div className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <h1>Welcome to Sharpclean</h1>
            <p>We Don't Cut Corners, We Clean Them!</p>
            <p>Our mission at Sharp Cleaning Solutions is to provide superior janitorial services that create clean, safe, and healthy environments for our clients. We aim to enhance the overall cleanliness and appearance of your space while promoting a positive and productive atmosphere for your employees and customers.</p>
            <div className="hero-buttons">
              <Link to="/apply-for-job" className="apply-button">Apply for Job</Link>
              <Link to="/book-now" className="book-button">Book Now</Link>
            </div>
          </div>
          <div className="hero-image">
            <img src="/media/cleaning.png" alt="Cleaning Image" />
          </div>
        </div>
      </div>

      <div className="partners-section">
        <h2>Our Partners</h2>
        <div className="partners-logos">
          <img src="/media/Drucker and Falk.png" alt="Drucker and Falk" />
          <img src="/media/Connor group.png" alt="Connor Group" />
          <img src="/media/rpm.png" alt="RPM" />
          <img src="/media/covenant properties.png" alt="Covenant Properties" />
          <img src="/media/two coast living.png" alt="Two Coast Living" />
          <img src="/media/lantower residential.png" alt="Lantower Residential" />
          <img src="/media/friedlam partners.png" alt="Friedlam Partners" />
          <img src="/media/hawthorn properties.png" alt="Hawthorn Properties" />
          <img src="/media/weinstein properties.png" alt="Weinstein Properties" />
        </div>
      </div>

      <section id="experience-difference" className="experience-difference">
        <div className="experience-container">
          <div className="image-container">
            <img src="/media/Cornell front.jpg" alt="Experience the Difference" className="rounded-image" />
          </div>
          <div className="text-container">
            <h2>Experience The Difference Of A Sparkling Home With Sharp Cleaning Solutions!</h2>
            <p>
              If you need cleaning help at home or in your office, “Sharp Cleaning Solutions” is here to help you. 
              Our team of experts is trained to leave your place tidy and smelling fresh. We offer different services, 
              including deep cleaning, moving cleaning, and recurring cleaning. We know that every home or office is different, 
              so we will work with you to create a personalized cleaning plan that meets your specific needs. 
              We always want to make sure that we are reliable, pay attention to every detail, and provide great customer service. 
              We are licensed and insured. Try our services today, and see how professional cleaning can make a difference.
            </p>
          </div>
        </div>
      </section>


      <div className="services-section">
        <h2>Our Services</h2>
        <div className="service-cards">
          <div className="service-card">
            <h3>Janitorial Services</h3>
            <p>Professional janitorial services for businesses of all sizes. We offer a wide range of janitorial services to meet your specific needs.</p>
          </div>
          <div className="service-card">
            <h3>Commercial Cleaning</h3>
            <p>Our experienced cleaners provide top-notch commercial cleaning services. We ensure a clean and hygienic environment for your workplace.</p>
          </div>
          <div className="service-card">
            <h3>Office Cleaning</h3>
            <p>Efficient and thorough office cleaning services to maintain a productive workspace. We clean and sanitize offices of all sizes.</p>
          </div>
          <div className="service-card">
            <h3>Floor Cleaning</h3>
            <p>We specialize in floor cleaning services, from mopping to buffing and waxing. We'll keep your floors looking spotless and well-maintained.</p>
          </div>
        </div>
      </div>

      <section className="image-comparison-section">
        <h2>Experience the Sharp Cleaning Solutions Touch! Our expert cleaners are ready to tackle any job, big or small, with the latest tools and techniques. No matter your home’s condition, we ensure it sparkles like new.
        </h2>
        <div className="image-comparison-container">
        <div className="comparison-column">
          <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src="/media/image3.jpeg" alt="before" style={{ height: '550px', objectFit: 'cover' }} />}
            itemTwo={<ReactCompareSliderImage src="/media/image2.jpeg" alt="after" style={{ height: '550px', objectFit: 'cover' }} />}
          />
          <p>Service 1 Before and After</p>
        </div>
        <div className="comparison-column">
          <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src="/media/image6.jpeg" alt="before" style={{ height: '550px', objectFit: 'cover' }} />}
            itemTwo={<ReactCompareSliderImage src="/media/image7.jpeg" alt="after" style={{ height: '550px', objectFit: 'cover' }} />}
          />
          <p>Service 2 Before and After</p>
        </div>

        </div>
      </section>

      <div className="facility-services">
        <h2>Our Platform Services</h2>
        <div className="facility-grid">
          <div className="facility-item">
            <i className="fas fa-comments"></i>
            <h3>Centralized Communication</h3>
            <p>Manage all cleaning-related communication in one convenient location, eliminating scattered emails and phone calls.</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-clipboard-check"></i>
            <h3>Request Work Orders</h3>
            <p>Easily submit work orders via direct message through the portal, ensuring prompt and efficient handling of your cleaning needs.</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-tachometer-alt"></i>
            <h3>Fast Response Rate</h3>
            <p>Experience swift responses to your cleaning requirements, ensuring your property remains clean and well-maintained at all times.</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-users"></i>
            <h3>Connect with Managers and Supervisors</h3>
            <p>Instantly connect with multiple managers and supervisors overseeing your cleaning services for effective collaboration.</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-chart-bar"></i>
            <h3>Detailed Inspection Reports</h3>
            <p>Access comprehensive inspection reports to monitor cleaning quality and address any concerns promptly, ensuring your property consistently meets high standards.</p>
          </div>
          <div className="facility-item">
            <i className="fas fa-comments"></i>
            <h3>Provide Feedback</h3>
            <p>Provide valuable feedback on the cleaning services rendered, allowing us to continuously improve and tailor our services to meet your evolving needs.</p>
          </div>
        </div>
      </div>

      <div className="about-us">
      <Link to="/about-us" className="about-link"><h2>About Us</h2></Link>
        <h2>The best facility care in Charlotte, North Carolina.</h2>
        
        <div className="social-icons">
          <a href="https://www.facebook.com/share/uXrDLyrFUEk3HtZQ/?mibextid=LQQJ4d"><i className="fab fa-facebook"></i></a>
          <a href="https://www.instagram.com/sharp_cleaningsolutions?igsh=MW5xNHNnbjdjbTZjeg%3D%3D&utm_source=qr"><i className="fab fa-instagram"></i></a>
          <a href="https://www.tiktok.com/@sharpcleaningsoulutions?_r=1&_d=eafj9f2cfmflc9&sec_uid=MS4wLjABAAAAGE1ZUhhjFsvT61So8zTgT9iFeVIvnTJKKqKf_I-h1pb7rLR31xFJcU_od-2PPHdK&share_author_id=7376317566417929258&sharer_language=en&source=h5_m&u_code=dcfibh8k8395h4&utm_source=sms&tt_from=sms&enable_checksum=1&utm_medium=ios&share_link_id=1E00C70E-2443-4E8D-BDFF-7D0FF5F1F463&user_id=6830507196146664454&sec_user_id=MS4wLjABAAAAG-ng4JfY59qh7laAyB4Feb5LDv4wHJvWuVfrxEoCbymiaG9uS2fXqfGepIuLF1Iy&social_share_type=5&ug_btm=b0,b5836&utm_campaign=client_share&enable_clips=1&share_app_id=1233"><i className="fab fa-tiktok"></i></a>
        </div>
        <hr />
        <div className="about-info">
          <div className="about-item">
            <h3>Address</h3>
            <p>Charlotte,<br />North Carolina</p>
          </div>
          <div className="about-item">
            <h3>Hours (Local time)</h3>
            <p>Monday - Friday | 9am - 5pm<br />Saturday | 10am - 5pm<br />Sundays & Holidays | Closed</p>
          </div>
          <div className="about-item">
            <h3>Contact</h3>
            <p>(980) 422-5603</p>
          </div>
        </div>
        <p>Copyright 2024 | Sharp Cleaning Solutions | Site by <a href="https://www.linkedin.com/in/jeromeadonis" target="_blank" rel="noopener noreferrer">J'erome Adonis</a></p>
      </div>
    </div>
  );
}

export default Home;
