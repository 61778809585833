import React from 'react';
import './styles/AboutUs.css'; // Import CSS for styling

function AboutUs() {
  return (
    <div className="about-us-page">
      <div className="background-image">
        <img src="/media/vacuum.jpg" alt="Background" />
      </div>
      <div className="gradient-overlay"></div>
      <div className="content-container">
        <h1>About Us</h1>
        <p>We Don't Cut Corners, We Clean Them!</p>
        <p>About Sharp Cleaning Solutions</p>
        <p>Our mission at Sharp Cleaning Solutions is to provide superior janitorial services that create clean, safe, and healthy environments for our clients. We aim to enhance the overall cleanliness and appearance of your space while promoting a positive and productive atmosphere for your employees and customers.</p>
        <p>Our Promise:</p>
        <ul>
          <li>Exceptional cleaning standards</li>
          <li>Trained, reliable, and friendly staff</li>
          <li>Ongoing communication and inspection</li>
          <li>Partnership to enhance guest perception</li>
        </ul>
        <div className="social-icons">
          <a href="https://www.facebook.com/share/uXrDLyrFUEk3HtZQ/?mibextid=LQQJ4d"><i className="fab fa-facebook"></i></a>
          <a href="https://www.instagram.com/sharp_cleaningsolutions?igsh=MW5xNHNnbjdjbTZjeg%3D%3D&utm_source=qr"><i className="fab fa-instagram"></i></a>
          <a href="https://www.tiktok.com/@sharpcleaningsoulutions?_r=1&_d=eafj9f2cfmflc9&sec_uid=MS4wLjABAAAAGE1ZUhhjFsvT61So8zTgT9iFeVIvnTJKKqKf_I-h1pb7rLR31xFJcU_od-2PPHdK&share_author_id=7376317566417929258&sharer_language=en&source=h5_m&u_code=dcfibh8k8395h4&utm_source=sms&tt_from=sms&enable_checksum=1&utm_medium=ios&share_link_id=1E00C70E-2443-4E8D-BDFF-7D0FF5F1F463&user_id=6830507196146664454&sec_user_id=MS4wLjABAAAAG-ng4JfY59qh7laAyB4Feb5LDv4wHJvWuVfrxEoCbymiaG9uS2fXqfGepIuLF1Iy&social_share_type=5&ug_btm=b0,b5836&utm_campaign=client_share&enable_clips=1&share_app_id=1233"><i className="fab fa-tiktok"></i></a>
        </div>
        <div className="contact-info">
          <div className="contact-item">
            <h3>Address</h3>
            <p>Charlotte, North Carolina</p>
          </div>
          <div className="contact-item">
            <h3>Hours (Local time)</h3>
            <p>Monday - Friday | 9am - 5pm<br />Saturday | 10am - 5pm<br />Sundays & Holidays | Closed</p>
          </div>
          <div className="contact-item">
            <h3>Contact</h3>
            <p>(980) 422-5603</p>
          </div>
          <div className="contact-item">
          </div>
        </div>
        <p>Copyright 2024 | Sharp Cleaning Solutions | Site by <a href="https://www.linkedin.com/in/jeromeadonis" target="_blank" rel="noopener noreferrer">J'erome Adonis</a></p>
      </div>
    </div>
  );
}

export default AboutUs;
