import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Frontend/Home'; // Adjusted path to match your project structure
import AboutUs from './Frontend/AboutUs'; // Adjusted path to match your project structure
import BookNow from './Frontend/BookNow'; // Adjusted path to match your project structure
import ApplyForJob from './Frontend/ApplyForJob'; // Adjusted path to match your project structure

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/book-now" element={<BookNow />} />
        <Route path="/apply-for-job" element={<ApplyForJob />} />
      </Routes>
    </Router>
  );
}

export default App;
