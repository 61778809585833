// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore} from 'firebase/firestore';
import { collection, addDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxQwKhrPuYvnwWfo2A-gZNqKPw4y_sKeM",
  authDomain: "sharp-clean-solutions.firebaseapp.com",
  projectId: "sharp-clean-solutions",
  storageBucket: "sharp-clean-solutions.appspot.com",
  messagingSenderId: "172971909276",
  appId: "1:172971909276:web:e48888dd54fd08a2cfcd59",
  measurementId: "G-0BHXFT70QC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, collection, addDoc};